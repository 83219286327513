import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Link from "@amzn/meridian/link";

import {
  ENABLE_YARD_DEVICES_MANAGEMENT,
  ENABLE_YARD_DOCUMENTS_MANAGEMENT,
  ENABLE_YARD_MAP_MANAGEMENT,
  YARD_LINKS,
} from "app-constants";

export default function YardLinks({ yardId }) {
  const featureFlags = useSelector((state) => state.featureFlags);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const links = [
    YARD_LINKS.config,
    ...(featureFlags[ENABLE_YARD_DEVICES_MANAGEMENT]?.enabled ? [YARD_LINKS.devices] : []),
    ...(featureFlags[ENABLE_YARD_DOCUMENTS_MANAGEMENT]?.enabled ? [YARD_LINKS.documents] : []),
    ...(featureFlags[ENABLE_YARD_MAP_MANAGEMENT]?.enabled ? [YARD_LINKS.map] : []),
  ];

  return (
    <>
      {links
        .map(({ path, text }) => (
          <Link
            data-testid={`yardLink-${path}`}
            type="secondary"
            href={`/yards/${yardId}/${path}`}
            onClick={() => navigate(`yards/${yardId}/${path}`)}
          >
            {t(text)}
          </Link>
        ))
        .map((node, i) => (
          <span key={node.props.href}>
            {node}
            {i !== links.length - 1 ? ", " : ""}
          </span>
        ))}
    </>
  );
}

YardLinks.propTypes = {
  yardId: PropTypes.string.isRequired,
};
